<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.emailtemplates')" class="settings connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-left">
              <span>{{!emailTemplateIdExternal ? $t('common.New_mail_template') : $t('common.Edit_mail_template')}}</span>
            </CCol>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="template.is_default_template" size="is-small">{{ $t('core.Set_as_default_template') }}</b-switch>
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="fullscreenMode" size="is-small" @input="toggleFullscreenMode()">{{ $t('connect.Fullscreen_email_editor') }}</b-switch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="loadingData">
            <CRow>
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="null"/>
              </CCol>
            </CRow>            
          </div>
          <div v-else>
            <CRow class="m-0">
              <CCol v-if="!fullscreenMode" cols="4" lg="4">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <CInput type="text" :label="$t('common.Name')" class="mb-0" v-model="template.name" required was-validated/>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <CTextarea @input="countdown()" :maxlength="maxCount" :label="$t('common.Description')" rows="5" class="mb-0" v-model="template.description" required was-validated/>
                    <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('common.characters_remaining') }}</p>
                  </CCol>
                </CRow>
              </CCol>
              <CCol :cols="editorCols.cols" :lg="editorCols.lg">
                <!-- <label>{{$t('common.Content_editor')}}</label> -->
                <EmailEditor ref="emailEditor"
                             :appearance="emailEditorConfig.appearance"
                             :min-height="emailEditorConfig.minHeight"
                             :project-id="emailEditorConfig.projectId"
                             :locale="emailEditorConfig.locale"
                             :tools="emailEditorConfig.tools"
                             :options="emailEditorConfig.options"
                             v-on:ready="editorReady()"/>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="!template.platform_event_email_template_id_external">
                  <CButton v-if="emailEditorReady" color="primary" @click="insertTemplate();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
                  <CButton color="secondary" @click="resetTemplateData(); showEmailTemplateOverview();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
                </div>
                <div v-else>
                  <CButton v-if="emailEditorReady" color="primary" @click="updateTemplate();"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
                  <CButton color="secondary" @click="showEmailTemplateOverview();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
                </div>                
              </CCol>                  
            </CRow>            
          </div>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Template',
  components: {
    QuickEdit,
    Multiselect,    
    EmailEditor,
    loadingSpinner,
    noPermission
  },
  watch: {
    $props: {
      handler() {
        if(this.$route.params.id !== undefined) this.emailTemplateIdExternal = this.$route.params.id;
        // Get the template details
        this.getTemplateDetails();        
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,
      emailTemplateIdExternal: null,
      fullscreenMode: false,
      editorCols: { cols: 8, lg: 8},
      template: {},
      maxCount: 1000,
      remainingCount: 1000,
      emailEditorReady: false,
      emailEditorConfig: {
        minHeight: '700px',
        locale: 'en',
        projectId: 50935, //'50935' = Harry HR
        tools: {
          image: { enabled: true } // Disable image tool
        },
        options: {
          mergeTags: {
            name: {
              name: "Employee Name",
              value: "{$name}"
            },
           ceo_name: {
              name: "Email Signatory",
              value: "{$email_signatory}"
            },            
            company: {
              name: "Platform Name",
              value: "{$platform_name}"
            },
            team: {
              name: "Employee Team",
              value: "{$team}"
            },
            department: {
              name: "Employee Department",
              value: "{$department}"
            },            
            points: {
              name: "Points",
              value: "{$points}"
            },                        
            compliment: {
              name: "Compliment",
              value: "{$compliment}"
            },
            breaking_news: {
              name: "Breaking News",
              value: "{$breaking_news}"
            },
            course: {
              name: "Completed Learn Course",
              value: "{$course}"
            },            
            knowledge_item: {
              name: "Knowledge Items",
              value: "{$knowledge_items}"
            },            
            evaluation_date: {
              name: "Evaluation Date",
              value: "{$evaluation_date}"
            },            
            email: {
              name: "Employee Email Address",
              value: "{$email}"
            },                                    
            copyright: {
              name: "Copyright",
              value: "{$copyright}"
            }                          
          },          
          specialLinks: [
            {
              name: 'Frequently used',
              specialLinks: [
                {
                  name: 'Link to Dashboard',
                  href: '{$dashboard_url}',
                  target: '_blank',
                },
                {
                  name: 'Link to Experience',
                  href: '{$app_url}',
                  target: '_blank',
                }
              ]
            }
          ]
        },
        appearance: {
          theme: 'light',
          panels: {
            tools: {
              dock: 'right',
            }
          }
        },
        // fonts: {
        //   showDefaultFonts: false,
        //   customFonts: [{
        //     label: "Quicksand",
        //     value: "'Quicksand', sans-serif",
        //     url: "https://fonts.googleapis.com/css?family=Quicksand:400,500,700"
        //   }]
        // }               
      }
    }
  },
  methods: {
    toggleFullscreenMode() {
      (this.fullscreenMode) ? this.editorCols = { cols: 12, lg: 12 } : this.editorCols = { cols: 8, lg: 8 };
    },
    getTemplateDetails() {
      if(this.emailTemplateIdExternal !== null) {
        // Start the loader
        this.loadingData = true;
        // Get the template details        
        axios.get(process.env.VUE_APP_API_URL + '/v1/core/email/template/' + this.emailTemplateIdExternal)
        .then(res => {
          this.template = res.data.data;
          // Trigger countdown function
          this.countdown();
          // Stop the loader
          this.loadingData = false;
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the data
        this.resetTemplateData();
      }
    },
    insertTemplate() {
      let params = {};
      params.templateData = this.template;
      params.templateData.json = null;
      (params.templateData.is_default_template === true) ? params.templateData.default_template = 'Y' : params.templateData.default_template = 'N';

      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.templateData.json = JSON.stringify(design);
        });
      }

      let name = params.templateData.name;
      let description = params.templateData.description;
    
      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/core/email/template', params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_added'), type: 'is-success', duration: 3000 });
            // Return to the email template overview
            this.showEmailTemplateOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    updateTemplate() {
      let params = {};
      params.templateData = this.template;
      params.templateData.json = null;
      (params.templateData.is_default_template === true) ? params.templateData.default_template = 'Y' : params.templateData.default_template = 'N';

      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.templateData.json = JSON.stringify(design);
        });
      }

      let name = params.templateData.name;
      let description = params.templateData.description;
    
      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description) {
          axios.put(process.env.VUE_APP_API_URL + '/v1/core/email/template/' + this.emailTemplateIdExternal, params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_updated'), type: 'is-success', duration: 3000 });
            // Return to the email template overview
            this.showEmailTemplateOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    resetTemplateData() {
      this.template = {
        platform_event_email_template_id_external: null,
        name: null,
        description: null
      }
    },
    editorReady() {
      this.emailEditorReady = true;
      // Get the mailDesign of the campaign
      let mailDesign = JSON.parse(this.template.json);
      // Load the mailDesign into the editor
      this.$refs.emailEditor.editor.loadDesign(mailDesign);
      // Set the body values of the editor
      this.$refs.emailEditor.editor.setBodyValues({
        backgroundColor: "#F6F6F6",
        textColor: '#4A4A4A',
        contentAlignment: 'center',
        links: {
          color: '#4A4A4A',
          underline: true
        }
      });
    },
    showEmailTemplateOverview() {            
      this.$router.push({path: '/core/email/templates'});      
    },
    countdown() {
      if(this.template.description) this.remainingCount = this.maxCount - this.template.description.length;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  mounted: function() {
    this.getPlatformPermissions();
  }
};
</script>